var mapStyle =[
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#e1d8d5"
            }
        ]
    }
];

/*id of map here*/
if ($("#mapalex").length > 0) {
  google.maps.event.addDomListener(window, 'load', initialize_map);
}

function initialize_map() {
  var controlPosition = google.maps.ControlPosition.RIGHT_CENTER;
  var coords_dynamic = jQuery("#mapalex").attr('data-coords');
  var splitedcoords = coords_dynamic.split(',');

  var myLatlng = new google.maps.LatLng(splitedcoords[0], splitedcoords[1]);

  var isDraggable = true;
  var mapOptions = {
    zoom: 13,
    gestureHandling: 'cooperative',
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
     styles:mapStyle,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles:mapStyle
  };


  var map = new google.maps.Map(document.getElementById('mapalex'),
    mapOptions);
  var iconsource = 'https://diatrofizois.com/wp-content/themes/diatrofi-zois/assets/images/marker.png';/*map pointer here*/
  var icons = {
    markericon: {
      icon: iconsource
    }
  };

  var marker = new google.maps.Marker({
    position: myLatlng,
    icon: {
url: "https://diatrofizois.com/wp-content/themes/diatrofi-zois/assets/images/marker.png",
size: new google.maps.Size(50, 50),
scaledSize: new google.maps.Size(50, 50),
origin: new google.maps.Point(0, 0),
anchor: new google.maps.Point(50,50)
},
    map: map
  });

  marker.setAnimation(google.maps.Animation.BOUNCE);
  setTimeout(function(){ marker.setAnimation(null); }, 1000);

  //Map style is not mandatory
  var styledMap = new google.maps.StyledMapType(mapStyle, {
    name: "Styled Map"
  });
  map.mapTypes.set('mapStyle', styledMap);
  map.setMapTypeId('mapStyle')

}

// Add alt text to Google Maps images
google.maps.event.addListener(this.map, 'tilesloaded', function(evt){
    $(this.getDiv()).find("img").each(function(i, eimg){
      if(!eimg.alt || eimg.alt ===""){
         eimg.alt = "Google Maps Image";
      }
    });
 });

/*Header*/
if($('body').hasClass('home')){
  window.onscroll = function () {
      scrollRotate();
  };

  function scrollRotate() {
      let image = document.getElementById("flower");
      image.style.transform = "rotate(" + window.pageYOffset/7 + "deg) translateY(50%)";
  }
}

$( document ).ready(function() {
      $('#flower').addClass('showme');
      $('.wrapper h1').addClass('showme');
});

/*hamburger*/
$(".menu_open button").on('click', function() {
      if($(this).hasClass('is-active')){
          $("body").css('overflow-y','scroll');
          $(this).removeClass('is-active');
          $('.mobile_menu').removeClass('is-active');
        }
      else{
          $(this).addClass('is-active');
          jQuery("body").css('overflow-y','hidden');
          $('.mobile_menu').addClass('is-active');
      }
});


/*Language Switcher*/
$(".lang_open>a").on('click', function() {
  if($('.lang_hidden').hasClass('openlang')){
      $('.lang_hidden').removeClass('openlang');
      $('.lang_hidden').css('display','none');
  }else{
    $('.lang_hidden').addClass('openlang');
    $('.lang_hidden').css('display','block');
  }
  return false;
});


/*Homepage About Parallax*/
if($('body').hasClass('home')){
  var scene_link = document.getElementById('about_image');
  var parallaxInstance = new Parallax(scene_link, {
    relativeInput: true
  });
}


/*Swipe Js*/
import Swiper from 'swiper';

      const swiper_gal = new Swiper(".services_swipe", {
        slidesPerView: "auto",
        centeredSlides: false,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '">' + (index + 1) + "</span>";
            },
          },
      });

      /* Homepage Effect Articles */
      if($('body').hasClass('home')){
        var targetOffset = $("#articles").offset().top - 400;
        var w = $(window).scroll(function(){
            if ( w.scrollTop() > targetOffset ) {
                $('.imagetitle').addClass('showme');
                $('#articles').addClass('showme');

            }
        });
      }

      /*Homepage Mouse over on Swipe*/
      jQuery(document).ready(function() {
      jQuery('.services_swipe .swiper-slide a').mousemove(function(event){

         var relX = event.pageX - jQuery(this).offset().left;
         var relY = event.pageY - jQuery(this).offset().top;

         if(relX<0){
           relX=0;
         }

         var product_container= jQuery(this).width();
         var mouseover_container=jQuery(this).find('.mousehover').width();
         if(relX>=(product_container -(mouseover_container/2) )){
           relX=product_container-(mouseover_container/2);
         }

         if(relY<0){
           relY=0;
         }

         var product_container_height= jQuery(this).height();
         var mouseover_container_height=jQuery(this).find('.mousehover').height();
         if(relY>=(product_container_height -(mouseover_container_height/2) )){
           relY=product_container_height-(mouseover_container_height/2);
         }

         var relBoxCoords = '(' + relX + ',' + relY + ')';

        jQuery(this).find('.mousehover').css('left',relX);
      jQuery(this).find('.mousehover').css('top',relY);
      jQuery(this).css('cursor','none');

        var mylink=$(this).find('a').attr('href');
      jQuery(this).click(function(){
         window.location = mylink;
         return true;
       });

      });
      });


      /*Category Filters*/
      $(".categories ul li a").on('click', function() {
        
        var clicked_cat=$(this).text();
        var clicked_post="";
        if (clicked_cat=="Ολα"){
            $(".marcticle" ).css('display','block');
        }else{
          $(".marcticle" ).each(function() {
              clicked_post=$(this).data("category");
              if(clicked_post==clicked_cat){
                $(this).css('display','block');
              }else{
                $(this).css('display','none');
              }
          });
        }
        return false;
      });
